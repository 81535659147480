import React from "react";
import { Patient } from "../../types/types";
import { Clinic } from "../../types/types";
import globalStyles from "../../App.module.scss";
import styles from "./AddPatient.module.scss";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Box, TextInput, NativeSelect } from "@mantine/core";
import { isInRange, isNotEmpty, useForm } from "@mantine/form";
import { DateInput } from "@mantine/dates";
import { useActions } from "../../app/hooks";

function AddPatient() {
  const [clinics, setClinics] = React.useState<Clinic[]>([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    const getAllClinics = async () => {
      const allClinics = await window.electron.clinic.getAll();
      setClinics(allClinics);
    };
    getAllClinics().catch((e) => console.log(e));
  }, []);

  const form = useForm<Omit<Patient, "id">>({
    initialValues: {
      name: "",
      surname: "",
      patronymic: "",
      dateOfBirth: "",
      createdAt: "",
      clinic_id: "",
      gender: "not selected",
      diagnosis: "",
      comment: "",
    },
    validate: {
      surname: isNotEmpty("Поле обязательно для заполнения"),
      clinic_id: isNotEmpty("Поле обязательно для заполнения"),
    },
  });

  const onClinicChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    form.setFieldValue("clinic_id", event.target.value);
  };

  const { addTab } = useActions();

  function choosePatient(patient: Patient) {
    addTab({ type: "patient", ...patient });
    navigate("/patient/" + patient.id);
  }

  const savePatientInfo = async (values: Omit<Patient, "id">) => {
    const patientInfo = structuredClone(values);
    patientInfo.dateOfBirth = new Date(values.dateOfBirth).toDateString();
    patientInfo.createdAt = new Date().toString();

    await window.electron.patient.create(patientInfo).then((response) => {
      choosePatient(response);
    });
  };

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        savePatientInfo(values).catch((e) => console.log(e));
      })}
      className={styles.addPatientFormContainer}
    >
      <h2 className={globalStyles.subHeader}>Новая карта пациента</h2>
      <div className={styles.addPatientFormFields}>
        <div className={styles.addPatientFormFieldsGroup}>
          <TextInput
            label="Фамилия"
            id="surname"
            {...form.getInputProps("surname")}
            size={"xs"}
            radius={"xs"}
            className={styles.addPatientFormField}
            classNames={{
              input: styles.inputField,
              label: styles.inputFieldLabel,
              wrapper: styles.inputFieldWrapper,
              error: styles.inputFieldError,
            }}
            withAsterisk
          />
          <TextInput
            label="Имя"
            id="name"
            {...form.getInputProps("name")}
            size={"xs"}
            radius={"xs"}
            className={styles.addPatientFormField}
            classNames={{
              input: styles.inputField,
              label: styles.inputFieldLabel,
            }}
          />
          <TextInput
            label="Отчество"
            id="patronymic"
            {...form.getInputProps("patronymic")}
            size={"xs"}
            radius={"xs"}
            className={styles.addPatientFormField}
            classNames={{
              input: styles.inputField,
              label: styles.inputFieldLabel,
            }}
          />
          <TextInput
            label="Диагноз"
            id="diagnosis"
            {...form.getInputProps("diagnosis")}
            size={"xs"}
            radius={"xs"}
            className={styles.addPatientFormField}
            classNames={{
              input: styles.inputField,
              label: styles.inputFieldLabel,
            }}
          />
        </div>
        <div className={styles.addPatientFormFieldsGroup}>
          <NativeSelect
            label="Пол"
            id="gender"
            {...form.getInputProps("gender")}
            size={"xs"}
            radius={"xs"}
            className={styles.addPatientFormField}
            classNames={{
              input: styles.inputField,
              label: styles.inputFieldLabel,
            }}
            data={[
              { value: "not selected", label: "—" },
              { value: "male", label: "Мужской" },
              { value: "female", label: "Женский" },
            ]}
          />
          <DateInput
            label="Дата рождения"
            id="dateOfBirth"
            {...form.getInputProps("dateOfBirth")}
            size={"xs"}
            radius={"xs"}
            placeholder="—"
            valueFormat="DD.MM.YYYY"
            className={styles.addPatientFormField}
            classNames={{
              input: styles.inputField,
              label: styles.inputFieldLabel,
            }}
          />
          <NativeSelect
            label="Клиника"
            id="clinic_id"
            {...form.getInputProps("clinic_id")}
            size={"xs"}
            radius={"xs"}
            className={styles.addPatientFormField}
            classNames={{
              input: styles.inputField,
              label: styles.inputFieldLabel,
              wrapper: styles.inputFieldWrapper,
              error: styles.inputFieldError,
            }}
            onChange={onClinicChange}
            data={[{ value: "0", label: "—" }].concat(
              clinics.map((clinic) => ({
                value: clinic.id!.toString(),
                label: clinic.name,
              }))
            )}
            withAsterisk
          />
          <TextInput
            label="Примечания"
            id="comment"
            {...form.getInputProps("comment")}
            size={"xs"}
            radius={"xs"}
            className={styles.addPatientFormField}
            classNames={{
              input: styles.inputField,
              label: styles.inputFieldLabel,
            }}
          />
        </div>
      </div>
      <Flex gap="15rem">
        <Box w={120}>
          <Button
            radius={"xs"}
            fullWidth
            variant="outline"
            className={globalStyles.simpleButton}
            onClick={() => navigate("/")}
          >
            Отмена
          </Button>
        </Box>
        <Box w={120}>
          <Button
            radius={"xs"}
            type="submit"
            fullWidth
            variant="outline"
            className={globalStyles.simpleButton}
          >
            Готово
          </Button>
        </Box>
      </Flex>
    </form>
  );
}

export default AddPatient;
