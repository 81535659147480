import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PatientWithClinicName } from "../../types/types";
import styles from "./Patients.module.scss";
import { useActions } from "../../app/hooks";
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from "mantine-react-table";
import { Box, Button, Flex } from "@mantine/core";
import globalStyles from "../../App.module.scss";
import { getPatientFullName } from "../../utils/utils";

function Patients() {
  const [patientsWithClinicName, setPatientsWithClinicName] = useState<
    PatientWithClinicName[]
  >([]);
  const [clinicsNames, setClinicsNames] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getPatientsWithClinicName = async () => {
      const data = await window.electron.patient.getPatientsWithClinicName();
      setPatientsWithClinicName(data);
      setClinicsNames(
        Array.from(new Set(data.map((patient) => patient.clinicName)))
      );
    };
    getPatientsWithClinicName().catch((e) => console.log(e));
  }, []);

  const { addTab } = useActions();

  function choosePatient(patient: PatientWithClinicName) {
    addTab({ type: "patient", ...patient });
    navigate("/patient/" + patient.id);
  }

  const columns = useMemo<MRT_ColumnDef<PatientWithClinicName>[]>(
    () => [
      {
        header: "ФИО",
        accessorFn: (row) => getPatientFullName(row),
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        header: "Клиника",
        accessorKey: "clinicName",
        filterVariant: "select",
        mantineFilterSelectProps: {
          data: clinicsNames, // select filter doesn't work, clinicsNames is an empty array
          placeholder: "Выберите название клиники",
          onDropdownOpen() {
            console.log("onDropdownOpen", patientsWithClinicName); // returns []
          },
        },
        enableGlobalFilter: false,
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        header: "Дата регистрации",
        accessorFn: (originalRow) =>
          new Date(originalRow.createdAt).toLocaleDateString("ru-RU"),
        enableGlobalFilter: false,
        filterVariant: "date",
        mantineFilterDateInputProps: {
          placeholder: "Выберите дату",
        },
        enableColumnFilter: true,
        enableColumnActions: false,
        enableSorting: false,
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: patientsWithClinicName,
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        choosePatient(row.original);
      },
      sx: {
        cursor: "pointer",
      },
    }),
    positionGlobalFilter: "left",
    enableColumnActions: true,
    enablePagination: false,
    enableBottomToolbar: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enableDensityToggle: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    columnFilterDisplayMode: "popover",
    mantineTableContainerProps: {
      sx: {
        maxHeight: "60vh",
      },
    },
    initialState: {
      showGlobalFilter: true,
      showColumnFilters: true,
    },
    mantineSearchTextInputProps: {
      placeholder: "Поиск по ФИО пациента",
      sx: { width: "620px" },
    },
    mantinePaperProps: {
      sx: {
        minWidth: "50%",
        margin: "2rem",
      },
    },
  });

  return (
    <div className={styles.tableContainer}>
      <MantineReactTable table={table} />
      <Flex gap="10rem">
        <Box w={220}>
          <Button
            radius={"xs"}
            fullWidth
            variant="outline"
            className={globalStyles.simpleButton}
          >
            Свободный фотопротокол
          </Button>
        </Box>
        <Box w={220}>
          <Button
            radius={"xs"}
            type="submit"
            fullWidth
            variant="outline"
            className={globalStyles.simpleButton}
            onClick={() => navigate("patient/create")}
          >
            Новый пациент
          </Button>
        </Box>
      </Flex>
    </div>
  );
}

export default Patients;
