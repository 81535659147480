import React, { useEffect, useState } from "react";
import { NavLink, Group, Button, Stack } from "@mantine/core";
import { IconUser, IconTrash } from "@tabler/icons-react";
import { User } from "../../types/types";
import { useNavigate } from "react-router-dom";
import { NavLink as RouterNavLink } from "react-router-dom";

function SelectUser() {
  const [users, setUsers] = useState<({ token: string } & User)[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.electron?.user?.getAll().then((response) => {
      setUsers(response);
    });
  }, []);

  async function selectUser(id: string) {
    const user = await window.electron?.user?.select(id);

    if (user?.token) {
      navigate("/");
    }
  }

  async function deleteUser(id: string) {
    await window.electron?.user.delete(id);
    window.electron?.user?.getAll().then((response) => {
      setUsers(response);
    });
  }

  return (
    <Stack>
      <Group position="center" mt={"lg"}>
        {users.length ? (
          users.map((el) => (
            <NavLink
              key={el.id}
              onClick={(e) => {
                if (e.defaultPrevented) {
                  return;
                }
                selectUser(el.id as string);
              }}
              label={el.email}
              description={`${(el.name, el.patronymic)}`}
              rightSection={
                <IconTrash
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    deleteUser(el.id as string);
                  }}
                  color="red"
                  size="2rem"
                  stroke={1.5}
                />
              }
              icon={<IconUser size="1rem" stroke={1.5} />}
            />
          ))
        ) : (
          <p>нет пользователей</p>
        )}
      </Group>

      <RouterNavLink to={"/login"}>
        <Button>Авторизоваться</Button>
      </RouterNavLink>
    </Stack>
  );
}

export default SelectUser;
