import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {Patient, PatientWithClinicName} from "../../types/types";

type Tab = {
  type: "patient";
  page: "main" | "protocol";
} & (Patient | PatientWithClinicName);

export type tabState = {
  tabs: Partial<Tab>[];
};

const initialState: tabState = {
  tabs: [],
};

export const tabSlice = createSlice({
  name: "tabStore",
  initialState,
  reducers: {
    addTab: (state, action: PayloadAction<Partial<Tab>>) => {
      if (state.tabs.some((tab) => tab.id === action.payload.id)) return;
      state.tabs.push({ ...action.payload, page: "main" });
    },
    removeTab: (state, action: PayloadAction<number>) => {
      state.tabs = state.tabs.filter((tab) => tab.id !== action.payload);
      return state;
    },
  },
});

export const tabReducer = tabSlice.reducer;
export const tabActions = tabSlice.actions;
