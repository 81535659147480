import React, { Suspense, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { MantineProvider } from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import "dayjs/locale/ru";

import LogIn from "./pages/logIn/LogIn";
import Layout from "./components/layout/Layout";
import Settings from "./pages/settings/Settings";
import UserPage from "./pages/user/User";
import Patients from "./pages/patients/Patients";
import AddPatient from "./pages/addPatient/AddPatient";
import PatientCard from "./pages/patientCard/PatientCard";
import PhotoProtocol from "./pages/photoProtocol/PhotoProtocol";
import ShowProtocol from "./pages/photoProtocol/ShowProtocol";
import EditPatient from "./pages/patientCard/EditPatientInfo";
import "./App.module.scss";
import { validateJWT } from "./utils/utils";
import AuthenticationForm from "./components/Auth/AuthForm";
import SelectUser from "./pages/logIn/SelectUser";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

const PrivateRoutes = () => {
  const location = useLocation();

  const [isValidated, setIsValidated] = useState<null | boolean>(null);

  useEffect(() => {
    const loadAuthToken = async () => {
      try {
        if (window.electron && window.electron.user) {
          const authToken = await window.electron.user.getToken();
          setIsValidated(validateJWT(authToken));
        } else {
          console.error("window.electron is not defined");
          setIsValidated(false);
        }
      } catch (error) {
        console.error("Ошибка при получении токена:", error);
        setIsValidated(false); // В случае ошибки, перенаправить пользователя на страницу входа
      }
    };
    loadAuthToken();
  }, []);
  if (isValidated === null) {
    return <div>Loading...</div>;
  }
  return isValidated ? (
    <Layout />
  ) : (
    <Navigate to="/login/select" replace state={{ from: location }} />
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<div>loading</div>}>
        {" "}
        <PrivateRoutes />
      </Suspense>
    ),
    children: [
      { path: "/", element: <Patients /> },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/user",
        element: <UserPage />,
      },
      {
        path: "/patient/create",
        element: <AddPatient />,
      },
      {
        path: "/patient/:id",
        element: <PatientCard />,
      },
      {
        path: "/patient/:id/protocol/create",
        element: <PhotoProtocol />,
      },
      {
        path: "/patient/:id/protocol/:protocol_id",
        element: <ShowProtocol />,
      },
      {
        path: "/clinic/create",
      },
      {
        path: "/patient/:id/edit",
        element: <EditPatient />,
      },
    ],
  },
  {
    path: "/login",
    element: <LogIn />,
    children: [
      { path: "/login", element: <AuthenticationForm /> },
      { path: "/login/select", element: <SelectUser /> },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider
        theme={{
          fontFamily: "Inter",
          colorScheme: "dark",
          colors: {
            dark: [
              "#eeeeee",
              "#e2e2e2",
              "#cbcbcb",
              "#bbbbbb",
              "#787878",
              "#484848",
              "#313131",
              "#272727",
              "#222222",
              "#161616",
            ],
          },
        }}
      >
        <DatesProvider
          settings={{ locale: "ru", firstDayOfWeek: 1, weekendDays: [0, 6] }}
        >
          <RouterProvider router={router} />
        </DatesProvider>
      </MantineProvider>
    </Provider>
  </React.StrictMode>
);
