import React, { useEffect, useRef, useState } from "react";
import styles from "./User.module.scss";
import globalStyles from "../../App.module.scss";
import { Clinic, EditableClinicRef, User } from "../../types/types";
import Icons from "../../icons/icons";
import EditUserInfo from "./EditUserInfo";
import EditableClinic from "./EditableClinic";
import { Button, Flex, Box, TextInput } from "@mantine/core";
import { NavLink } from "react-router-dom";

function UserPage() {
  const [user, setUser] = useState<User>({} as User);
  const [isUserEdited, setIsUserEdited] = useState<boolean>(false);

  const [clinics, setClinics] = useState<Clinic[]>([]);
  const [areClinicsEdited, setAreClinicsEdited] = useState<boolean>(false);
  const [editedClinics, setEditedClinics] = useState<Clinic[]>([]);
  const [newClinic, setNewClinic] = useState<Clinic>({ id: "", name: "" });
  const [clinicsChanges, setClinicsChanges] = useState<{
    created: Clinic[];
    updated: Clinic[];
    deleted: Clinic[];
  }>({ created: [], updated: [], deleted: [] });
  const [isOneClinicBeingEdited, setIsOneClinicBeingEdited] =
    useState<boolean>(false);
  const [isAlertForNewClinicShown, setIsAlertForNewClinicShown] =
    useState<boolean>(false);
  const [isAlertForEditedClinicShown, setIsAlertForEditedClinicShown] =
    useState<boolean>(false);

  const editableClinicRef = useRef<EditableClinicRef>(null);

  const sameClinicNameAlert = "Клиника с таким названием уже существует";

  useEffect(() => {
    const getUser = async () => {
      const data = await window.electron.user.get();
      setUser(data);
    };

    getUser().catch((e) => console.error);
  }, []);

  useEffect(() => {
    const getAllClinics = async () => {
      const allClinics = await window.electron.clinic.getAll();
      setClinics(allClinics);
    };

    getAllClinics().catch((e) => console.error);
  }, []);

  const getFullName = () => {
    const surname = user.surname;
    const name = user.name;
    const patronymic = user.patronymic;
    return surname + " " + name + " " + patronymic;
  };

  const turnOnUserEditingMode = () => {
    setIsUserEdited(true);
  };

  const turnOnClinicsEditingMode = () => {
    setEditedClinics(structuredClone(clinics));

    setAreClinicsEdited(true);
  };

  const isClinicNameNotUnique = (name: string) => {
    return editedClinics.find((clinic) => clinic.name === name);
  };

  const saveClinics = () => {
    let createdClinics: Clinic[] = clinicsChanges.created;
    let updatedClinics: Clinic[] = clinicsChanges.updated;

    if (newClinic.name !== "") {
      createdClinics = handleAddClinic() ?? clinicsChanges.created;
    }

    if (
      editedClinics.length > 0 &&
      editableClinicRef.current &&
      editableClinicRef.current.isClinicEditing
    ) {
      const changedName = editableClinicRef.current.editableClinic.changedName;
      const id = editableClinicRef.current.editableClinic.id;
      const prevName = editableClinicRef.current.editableClinic.prevName;
      updatedClinics =
        handleSaveEditedClinic(changedName, id, prevName) ??
        clinicsChanges.updated;
    }

    createdClinics.forEach((clinic) => {
      window.electron.clinic
        .create(clinic.name)
        .catch((error) => console.log(error));
    });

    updatedClinics.forEach((clinic) => {
      window.electron.clinic
        .update(clinic)
        .catch((error) => console.log(error));
    });

    clinicsChanges.deleted.forEach((clinic) => {
      if (clinic.id) {
        window.electron.clinic
          .remove(clinic.id)
          .catch((error) => console.log(error));
      }
    });

    setClinics(editedClinics);
    setAreClinicsEdited(false);
    setClinicsChanges({ created: [], updated: [], deleted: [] });
  };

  const handleChangeNewClinicInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewClinic({ id: "", name: event.target.value });
    if (isAlertForNewClinicShown && isClinicNameNotUnique(newClinic.name)) {
      setIsAlertForNewClinicShown(false);
    }
  };

  const handleAddClinic = () => {
    if (isClinicNameNotUnique(newClinic.name)) {
      setIsAlertForNewClinicShown(true);
      return;
    }

    editedClinics.push({ id: "", name: newClinic.name });
    setEditedClinics([...editedClinics]);

    const createdClinics = [
      ...clinicsChanges.created,
      { id: "", name: newClinic.name },
    ];
    setClinicsChanges({
      ...clinicsChanges,
      created: createdClinics,
    });

    setNewClinic({ id: "", name: "" });

    return createdClinics;
  };

  const handleSaveEditedClinic = (
    changedName: string,
    id: string,
    prevName: string
  ) => {
    let clinic;
    let clinicFromChanges;

    if (isClinicNameNotUnique(changedName)) {
      setIsAlertForEditedClinicShown(true);
      return;
    }

    if (id) {
      clinic = editedClinics.find((clinic) => clinic.id === id);
    } else {
      clinic = editedClinics.find((clinic) => clinic.name === prevName);
      clinicFromChanges = clinicsChanges.created.find(
        (clinic) => clinic.name === prevName
      );
    }

    if (clinic && clinic.name && clinic.name !== changedName) {
      clinic.name = changedName;
    }

    if (
      clinicFromChanges &&
      clinicFromChanges.name &&
      clinicFromChanges.name !== changedName
    ) {
      clinicFromChanges.name = changedName;
    }

    const updatedClinics = [
      ...clinicsChanges.updated,
      { id: id, name: changedName },
    ];

    setClinicsChanges({
      ...clinicsChanges,
      updated: updatedClinics,
    });

    editableClinicRef.current &&
      editableClinicRef.current.setIsClinicEditing(false);
    setIsOneClinicBeingEdited(false);

    return updatedClinics;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && newClinic.name !== "") {
      handleAddClinic();
    }
  };

  const handleDeleteClinic = (deletedClinic: Clinic) => {
    if (deletedClinic.id) {
      const deletedClinics = [...clinicsChanges.deleted, deletedClinic];

      setClinicsChanges({
        ...clinicsChanges,
        deleted: deletedClinics,
      });

      setEditedClinics(
        editedClinics.filter((clinic) => clinic.id !== deletedClinic.id)
      );
    } else {
      const updatedCreatedClinic = clinicsChanges.created.filter(
        (clinic) => clinic.name !== deletedClinic.name
      );

      setClinicsChanges({
        ...clinicsChanges,
        created: updatedCreatedClinic,
      });

      setEditedClinics(
        editedClinics.filter((clinic) => clinic.name !== deletedClinic.name)
      );
    }
  };

  const clinicsList = clinics.map((clinic, index) => (
    <div className={styles.clinicItem} key={index}>
      <p className={styles.clinicName}>{clinic.name}</p>
    </div>
  ));

  const editedClinicsList = editedClinics.map((clinic, index) => (
    <EditableClinic
      clinic={clinic}
      key={index}
      ref={editableClinicRef}
      isAlertForEditedClinicShown={isAlertForEditedClinicShown}
      setIsAlertForEditedClinicShown={setIsAlertForEditedClinicShown}
      isOneClinicBeingEdited={isOneClinicBeingEdited}
      setIsOneClinicBeingEdited={setIsOneClinicBeingEdited}
      onDeleteClinic={handleDeleteClinic}
      onSaveEditedClinic={handleSaveEditedClinic}
      sameClinicNameAlert={sameClinicNameAlert}
      isClinicNameNotUnique={isClinicNameNotUnique}
    />
  ));

  const turnOffClinicsEditingMode = () => {
    setNewClinic({ id: "", name: "" });
    setAreClinicsEdited(false);
    setClinicsChanges({ created: [], updated: [], deleted: [] });
    setIsOneClinicBeingEdited(false);
  };

  return (
    <>
      <h1 className={globalStyles.mainHeader}>Личный кабинет</h1>
      <div className={styles.container}>
        <img src={Icons.Avatar} alt="avatar" className={styles.avatar} />
        {isUserEdited ? (
          <EditUserInfo
            isUserEdited={isUserEdited}
            user={user}
            setIsUserEdited={setIsUserEdited}
            setUser={setUser}
          />
        ) : (
          <div>
            <div className={styles.information}>
              <div className={styles.viewMode}>
                <div>
                  <h3 className={globalStyles.subHeader}>Мои данные</h3>
                  <div className={styles.personalInfo}>
                    <p className={styles.personalInfoItem}>{getFullName()}</p>
                    <p className={styles.personalInfoItem}>
                      {user.speciality ? user.speciality : "Специальность"}
                    </p>
                    <p className={styles.personalInfoItem}>
                      {user.email ? user.email : "E-mail"}
                    </p>
                  </div>
                </div>
                {!areClinicsEdited && (
                  <div>
                    <img
                      src={Icons.Pencil}
                      onClick={turnOnUserEditingMode}
                      alt="Pencil icon"
                      className={globalStyles.editButton}
                    />
                  </div>
                )}
              </div>

              <div className={styles.clinicsSection}>
                <div className={styles.viewMode}>
                  <div
                    style={
                      areClinicsEdited ? { width: "300px" } : { width: "250px" }
                    }
                  >
                    <h3 className={globalStyles.subHeader}>Мои клиники</h3>
                    <div className={styles.clinicsList}>
                      {areClinicsEdited ? editedClinicsList : clinicsList}
                    </div>
                  </div>
                  {!areClinicsEdited && (
                    <div>
                      <img
                        src={Icons.Pencil}
                        onClick={turnOnClinicsEditingMode}
                        alt="Pencil icon"
                        className={globalStyles.editButton}
                      />
                    </div>
                  )}
                </div>

                {areClinicsEdited && (
                  <>
                    <div>
                      <div className={styles.addClinicField}>
                        <div style={{ width: "70%" }}>
                          <TextInput
                            radius={"xs"}
                            placeholder="Название клиники"
                            value={newClinic.name}
                            classNames={{ input: styles.addClinicInput }}
                            onChange={handleChangeNewClinicInput}
                            onKeyDown={handleKeyDown}
                          />
                          <p
                            className={
                              isAlertForNewClinicShown
                                ? globalStyles.alertMessage
                                : globalStyles.hiddenAlertMessage
                            }
                          >
                            {sameClinicNameAlert}
                          </p>
                        </div>
                        <img
                          style={{ margin: "5.5px 10px" }}
                          src={Icons.Plus}
                          alt="Plus icon"
                          className={globalStyles.plusButton}
                          onClick={
                            newClinic.name !== "" ? handleAddClinic : undefined
                          }
                        />
                      </div>
                    </div>

                    <Flex gap="3.2rem">
                      <Box w={120}>
                        <Button
                          radius={"xs"}
                          fullWidth
                          variant="outline"
                          className={globalStyles.simpleButton}
                          onClick={turnOffClinicsEditingMode}
                        >
                          Отмена
                        </Button>
                      </Box>

                      <Box w={120}>
                        <Button
                          radius={"xs"}
                          fullWidth
                          variant="outline"
                          className={globalStyles.simpleButton}
                          onClick={saveClinics}
                        >
                          Сохранить
                        </Button>
                      </Box>
                    </Flex>
                  </>
                )}
              </div>
            </div>
            <Box>
              <NavLink to={"/login"}>
                <Button mx={10}>Выйти</Button>
              </NavLink>
              <NavLink to={"/login/select"}>
                <Button>Сменить пользователя</Button>
              </NavLink>
            </Box>
          </div>
        )}
      </div>
    </>
  );
}

export default UserPage;
