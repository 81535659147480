import React, { useEffect, useState } from "react";
import { Button, TextInput } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { Patient } from "../../types/types";

function PhotoProtocol() {
  interface FileWithPath extends File {
    path?: string;
  }

  const { id } = useParams();
  const [protocolName, setProtocolName] = useState<string>("");
  const [photos, setPhotos] = useState<FileWithPath[]>([]);
  const [patient, setPatient] = useState<Patient>();

  const navigate = useNavigate();
  const generateDefaultProtocolName = () => {
    const currentDate = new Date().toLocaleDateString("en-GB");
    // Assuming patientName is available
    const patientName = patient?.name; // Replace with actual patient name
    if (!patientName) return "";
    const initials = patientName
      .split(" ")
      .map((word) => word.charAt(0))
      .join("");
    return `${currentDate} - ${patientName} ${initials}`;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const selectedFiles = Array.from(e.target.files);
    const imageFiles = selectedFiles.filter(
      (file) => file.type === "image/jpeg" || file.type === "image/png"
    );
    setPhotos([...photos, ...imageFiles]);
  };

  useEffect(() => {
    const getPatient = async () => {
      const patient = await window.electron.patient.get(parseInt(id as string));
      setPatient(patient);
    };
    getPatient();
  }, []);

  useEffect(() => {
    const name = generateDefaultProtocolName();
    setProtocolName(name);
  }, [patient]);

  const handleSave = async () => {
    // Handle saving logic here
    const clonePhotos = photos.map((ph) => {
      return { path: ph.path, name: ph.name };
    });
    if (!id) return;
    await window.electron.protocol
      .create(clonePhotos, id, protocolName)
      .then((resp) => {
        console.log(resp);
        //redirect to photoprotocol page
        navigate(`/patient/${patient?.id}/protocol/${resp}`);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return (
    <div>
      <h2>Создание нового фотопротокола</h2>
      <TextInput
        value={protocolName}
        onChange={(e) => setProtocolName(e.target.value)}
      />
      <input
        type="file"
        accept="image/jpeg, image/png"
        multiple
        onChange={handleFileChange}
        style={{ opacity: "0", height: 0, width: 0 }}
      />
      <div>
        {photos.map((photo, index) => (
          <img
            key={index}
            src={URL.createObjectURL(photo)}
            alt={`Photo ${index + 1}`}
            style={{ maxWidth: "200px", maxHeight: "200px", margin: "5px" }}
          />
        ))}
      </div>
      <Button
        onClick={() =>
          (document.querySelector("input[type='file']") as HTMLElement).click()
        }
      >
        Добавить
      </Button>
      <Button onClick={handleSave}>Сохранить</Button>
    </div>
  );
}

export default PhotoProtocol;
