import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Patient } from "../../types/types";
import { Clinic } from "../../types/types";
import globalStyles from "../../App.module.scss";
import styles from "./EditPatientInfo.module.scss";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Box, TextInput, NativeSelect } from "@mantine/core";
import { isInRange, isNotEmpty, useForm } from "@mantine/form";
import { DateInput } from "@mantine/dates";
import { useActions } from "../../app/hooks";

function EditPatient() {
  const [clinics, setClinics] = React.useState<Clinic[]>([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [patientToUpdate, setPatientToUpdate] = useState<
    Omit<Patient, "createdAt">
  >({
    id: 0,
    name: "",
    surname: "",
    patronymic: "",
    gender: "not selected",
    diagnosis: "",
    comment: "",
    dateOfBirth: "",
    clinic_id: "",
  });

  const getPatientToUpdate = async () => {
    const data = await window.electron.patient.get(parseInt(id as string));
    return data;
  };

  React.useEffect(() => {
    const getAllClinics = async () => {
      const allClinics = await window.electron.clinic.getAll();
      setClinics(allClinics);
    };
    getAllClinics().catch((e) => console.log(e));
  }, []);

  React.useEffect(() => {
    getPatientToUpdate()
      .then((data) => {
        setPatientToUpdate(data);
        const DOB = new Date(data.dateOfBirth);
        form.setValues({
          id: parseInt(id as string),
          name: data.name,
          surname: data.surname,
          patronymic: data.patronymic,
          clinic_id: data.clinic_id,
          gender: data.gender,
          diagnosis: data.diagnosis,
          comment: data.comment,
          dateOfBirth: isNaN(DOB.getTime()) ? "" : DOB,
        });
      })
      .catch((e) => console.error);
  }, [id]);

  const form = useForm<Omit<Patient, "createdAt">>({
    initialValues: {
      id: parseInt(id as string),
      name: patientToUpdate.name,
      surname: patientToUpdate.surname,
      patronymic: patientToUpdate.patronymic,
      dateOfBirth: patientToUpdate.dateOfBirth,
      clinic_id: patientToUpdate.clinic_id,
      gender: patientToUpdate.gender,
      diagnosis: patientToUpdate.diagnosis,
      comment: patientToUpdate.comment,
    },
    validate: {
      surname: isNotEmpty("Поле обязательно для заполнения"),
      clinic_id: isNotEmpty("Поле обязательно для заполнения"),
    },
  });

  const onClinicChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    form.setFieldValue("clinic_id", event.target.value);
  };

  const { addTab } = useActions();

  const back = async () => {
    navigate("/patient/" + patientToUpdate.id);
  };

  function choosePatient(patient: Omit<Patient, "createdAt">) {
    addTab({ type: "patient", ...patient });
    navigate("/patient/" + patient.id);
  }

  const saveUpdatedPatientInfo = async (values: Omit<Patient, "createdAt">) => {
    const patientInfo = structuredClone(values);
    console.log(values);
    patientInfo.dateOfBirth = new Date(values.dateOfBirth).toDateString();

    await window.electron.patient.update(patientInfo);
    choosePatient(patientInfo);
  };

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        saveUpdatedPatientInfo(values).catch((e) => console.log(e));
      })}
      className={styles.addPatientFormContainer}
    >
      <h2 className={globalStyles.subHeader}>
        Редактирование карты пациента №{id}
      </h2>
      <div className={styles.addPatientFormFields}>
        <div className={styles.addPatientFormFieldsGroup}>
          <TextInput
            label="Фамилия"
            id="surname"
            {...form.getInputProps("surname")}
            size={"xs"}
            radius={"xs"}
            className={styles.addPatientFormField}
            classNames={{
              input: styles.inputField,
              label: styles.inputFieldLabel,
              wrapper: styles.inputFieldWrapper,
              error: styles.inputFieldError,
            }}
            withAsterisk
          />
          <TextInput
            label="Имя"
            id="name"
            {...form.getInputProps("name")}
            size={"xs"}
            radius={"xs"}
            className={styles.addPatientFormField}
            classNames={{
              input: styles.inputField,
              label: styles.inputFieldLabel,
            }}
          />
          <TextInput
            label="Отчество"
            id="patronymic"
            {...form.getInputProps("patronymic")}
            size={"xs"}
            radius={"xs"}
            className={styles.addPatientFormField}
            classNames={{
              input: styles.inputField,
              label: styles.inputFieldLabel,
            }}
          />
          <TextInput
            label="Диагноз"
            id="diagnosis"
            {...form.getInputProps("diagnosis")}
            size={"xs"}
            radius={"xs"}
            className={styles.addPatientFormField}
            classNames={{
              input: styles.inputField,
              label: styles.inputFieldLabel,
            }}
          />
        </div>
        <div className={styles.addPatientFormFieldsGroup}>
          <NativeSelect
            label="Пол"
            id="gender"
            {...form.getInputProps("gender")}
            size={"xs"}
            radius={"xs"}
            className={styles.addPatientFormField}
            classNames={{
              input: styles.inputField,
              label: styles.inputFieldLabel,
            }}
            data={[
              { value: "not selected", label: "—" },
              { value: "male", label: "Мужской" },
              { value: "female", label: "Женский" },
            ]}
          />
          <DateInput
            label="Дата рождения"
            id="dateOfBirth"
            {...form.getInputProps("dateOfBirth")}
            size={"xs"}
            radius={"xs"}
            placeholder="—"
            valueFormat="DD.MM.YYYY"
            className={styles.addPatientFormField}
            classNames={{
              input: styles.inputField,
              label: styles.inputFieldLabel,
            }}
          />
          <NativeSelect
            label="Клиника"
            id="clinic_id"
            {...form.getInputProps("clinic_id")}
            size={"xs"}
            radius={"xs"}
            className={styles.addPatientFormField}
            classNames={{
              input: styles.inputField,
              label: styles.inputFieldLabel,
              wrapper: styles.inputFieldWrapper,
              error: styles.inputFieldError,
            }}
            onChange={onClinicChange}
            data={[{ value: "0", label: "—" }].concat(
              clinics.map((clinic) => ({
                value: clinic.id,
                label: clinic.name,
              }))
            )}
            withAsterisk
          />
          <TextInput
            label="Примечания"
            id="comment"
            {...form.getInputProps("comment")}
            size={"xs"}
            radius={"xs"}
            className={styles.addPatientFormField}
            classNames={{
              input: styles.inputField,
              label: styles.inputFieldLabel,
            }}
          />
        </div>
      </div>
      <Flex gap="15rem">
        <Box w={120}>
          <Button
            radius={"xs"}
            fullWidth
            variant="outline"
            className={globalStyles.simpleButton}
            onClick={back}
          >
            Отмена
          </Button>
        </Box>
        <Box w={120}>
          <Button
            radius={"xs"}
            type="submit"
            fullWidth
            variant="outline"
            className={globalStyles.simpleButton}
          >
            Готово
          </Button>
        </Box>
      </Flex>
    </form>
  );
}

export default EditPatient;
