import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Patient } from "../../types/types";

export default function ShowProtocol() {
  const [photos, setPhotos] = useState<{ data: Buffer; filename: string }[]>();
  const [name, setName] = useState("");
  const [patient, setPatient] = useState<Patient>();

  const { id, protocol_id } = useParams();

  React.useEffect(() => {
    const getPatient = async () => {
      const data = await window.electron.patient.get(parseInt(id as string));
      setPatient(data);
    };
    getPatient().catch((e) => console.error);
  }, [id]);

  useEffect(() => {
    const getProtocol = async () => {
      const protocol = await window.electron.protocol.getFiles(
        parseInt(protocol_id as string)
      );
      console.log(protocol);
      setPhotos(protocol?.files);
      setName(protocol?.name);
    };
    getProtocol();
  }, [protocol_id]);
  return (
    <>
      <div>
        {photos?.map((photo, index) => (
          <img
            key={index}
            src={URL.createObjectURL(
              new Blob([Uint8Array.from(photo.data)], { type: "image/jpeg" })
            )}
            alt={`Photo ${index + 1}`}
            style={{ maxWidth: "200px", maxHeight: "200px", margin: "5px" }}
          />
        ))}
      </div>
    </>
  );
}
