import React from "react";
import { Group } from "@mantine/core";
import { Outlet } from "react-router-dom";

function LogIn() {
  
  return (
    <Group position="center" mt={"lg"}>
      <Outlet></Outlet>
    </Group>
  );
}

export default LogIn;
